import { startOfToday, subDays } from 'date-fns';
import { ICompanyState } from 'store/company/interfaces';
import moment from 'moment';

const earliestServiceStartDate = new Date('03/01/2023');

export const getBirthDate = (border: string) => {
  const today = new Date(new Date().setHours(0, 0, 0, 0));
  if (border === 'min')
    return new Date(today.getFullYear() - 120, today.getMonth(), today.getUTCDate());
  else if (border === 'max')
    return new Date(today.getFullYear() - 10, today.getMonth(), today.getUTCDate());
  else return new Date();
};

export const getMinStartDate = (company: ICompanyState | null, endDate?: Date | null | string) => {
  if (endDate) {
    const temDate = new Date(endDate);
    //I hardcoded this value because this code needs just for the profit company
    //and just for some special use case, we definitely need to choose the first day of the month
    return new Date(temDate.getFullYear(), temDate.getMonth(), 1);
  }
  const today = new Date(new Date().setHours(0, 0, 0, 0));
  if (company === null) return today;

  let minimumDate = new Date(today.getFullYear(), today.getMonth() + 1, 1);
  if (company.regularStartDate === 'flexible') {
    minimumDate = new Date(today.setDate(today.getDate() + 1));
  }
  let startDate = earliestServiceStartDate;
  if (company?.startDate) {
    startDate = new Date(company.startDate);
  }
  if (minimumDate < startDate) {
    minimumDate = startDate;
  }

  return minimumDate;
};
export const getMaxStartDate = (company: ICompanyState | null) => {
  const today = startOfToday();
  if (company === null) {
    return today;
  }

  const monthLimit = 6;
  const firstDate = new Date(today.getFullYear(), today.getMonth(), 1);
  const nextSixMonth = moment(firstDate).add(monthLimit, 'M').toDate();
  if (company.endDate) {
    const date = new Date(company.endDate);
    return date < nextSixMonth ? date : nextSixMonth;
  } else {
    return nextSixMonth;
  }
};
