import { IViolationsError } from 'store/company/interfaces';
import { useAppDispatch, useAppSelector } from 'store';
import { Box, Button, Typography } from '@mui/material';
import { Stepper } from '..';
import { FormProvider, useForm } from 'react-hook-form';
import PersonalData from '../FormParts/PersonalData';
import { format } from 'date-fns';
import { actions } from 'store/company/company.slice';
import { useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useMemo, useState } from 'react';
import { CompanySelectors } from 'store/company/company.selectors';
import { yupResolver } from '@hookform/resolvers/yup';
import personalInfoSchema from '../StepOne/contactSchema';
import ModalError from 'components/ModalError';
import { useViolationErrors } from 'utils';
import { useIntl } from 'react-intl';
import ContactInfo from '../FormParts/ContactInfo';
import Spacer from 'components/Spacer';
import getContactsSchema from 'utils/getContactsSchema';
import { prepareSaveData } from 'utils/prepareSavedData';
import CCPTextField from 'components/FormComponents/TextField';
import { getBirthDate, getMaxStartDate, getMinStartDate } from 'utils/prepareDates';
import { isDisabledField } from 'utils/disablePrefilledFields';
import { isHiddenField } from 'utils/hidePrefilledFields';
import { distinctArrayValues } from 'utils/distinctArrayValues';
import ErrorIcon from '@mui/icons-material/Error';
import StartDatePicker from '../FormParts/StartDatePicker';
import RequiredInfo from '../FormParts/RequiredInfo';

interface StepOneUTwoProps {
  updateStep: (stepNumber: number | string) => void;
  sendRequest: (error: IViolationsError[]) => void;
}

const StepOneUTwo: React.FC<StepOneUTwoProps> = ({ updateStep, sendRequest }) => {
  const { stepNumber } = useParams();
  const navigate = useNavigate();
  const company = useAppSelector(CompanySelectors.getCompanyData);
  const userInfo = useAppSelector(CompanySelectors.getUserInfo);
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const paymentValue = useAppSelector(state => state.company.vouchers.data?.payableAmount);

  const hasStep3 =
    company && company.paymentProviders?.length !== 0 && paymentValue?.toString() !== '0';
  const [existingUserError, setExistingUserError] = useState<boolean>(false);
  const violationErrors = useAppSelector(CompanySelectors.getViolationErrors);

  const countries = useAppSelector(state => state.company.countriesList.data) || [];
  const minimumDate = getMinStartDate(company);
  const maximumDate = getMaxStartDate(company);

  const disabledFields = useAppSelector(CompanySelectors.getDisabledFields) || [];
  const formHiddenFields = useAppSelector(CompanySelectors.getHiddenFields) || [];
  const fullHiddenFields = useAppSelector(CompanySelectors.getFullHiddenFields) || [];
  const hiddenFields = distinctArrayValues(formHiddenFields, fullHiddenFields);

  const durartion =
    userInfo?.duration && userInfo?.duration?.length < 3
      ? `${userInfo?.duration?.slice(0, -1)} ${intl.formatMessage({
          id: 'step1.form.duration.content',
          defaultMessage: 'months',
        })}`
      : userInfo?.duration;

  useEffect(() => {
    if (company && !company.startDate) {
      navigate(`/`);
    }
  }, [company, navigate]);

  const schema: any = useMemo(() => {
    const personalInfo = personalInfoSchema(
      { min: getBirthDate('min'), max: getBirthDate('max') },
      { min: minimumDate, max: maximumDate },
    );
    let EditEmployeeSchemaShaped = getContactsSchema(company, violationErrors, userInfo, false);

    EditEmployeeSchemaShaped = EditEmployeeSchemaShaped.shape({
      ...personalInfo.fields,
    });

    return EditEmployeeSchemaShaped;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company]);

  const countryGlobalField = company?.globalCustomFields?.find(
    globalItem => globalItem.key === 'hansefit_country',
  );

  const renewMembershipDate = getMinStartDate(company, userInfo?.end_date_reached);

  const methods = useForm({
    defaultValues: {
      title: userInfo?.title || '',
      firstName: userInfo?.firstName || '',
      lastName: userInfo?.lastName || '',
      gender: userInfo?.gender || '',
      dateOfBirth: userInfo?.dateOfBirth || null,
      startDate: format(
        new Date(
          userInfo?.end_date_reached ? renewMembershipDate : userInfo?.startDate || minimumDate,
        ),
        'yyyy-MM-dd',
      ),
      businessEmail: userInfo?.businessEmail || '',
      confirmEmail: userInfo?.businessEmail || '',
      duration: durartion || '',
      // phoneNumberPrefix: userInfo?.phoneNumber ? getPhonePrefix(userInfo.phoneNumber, dialCodes) : '+49',
      //  phoneNumber: userInfo?.phoneNumber ? getPhoneWithoutPrefix(userInfo.phoneNumber, dialCodes) : '',
      customFields: userInfo?.customFields || {},
      globalCustomFields:
        userInfo?.globalCustomFields ||
        (!!countryGlobalField && countries?.find(item => item.code === 'DE')
          ? { [countryGlobalField.key]: 'DE' }
          : {}),
    },
    resolver: yupResolver(schema),
  });

  const { control, setError, handleSubmit, getValues, watch, clearErrors } = methods;

  const addViolationErrors = useViolationErrors({
    errors: violationErrors || null,
    scope: 'employee_management',
    watch,
    clearErrors,
  });

  useEffect(() => {
    addViolationErrors.forEach(error => {
      if (error.code.toUpperCase() === 'NOT_UNIQUE_NAME_AND_BIRTHDATE_IN_COMPANY') {
        setExistingUserError(true);
      } else {
        console.log('first step violation error');
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [violationErrors]);

  useEffect(() => {
    addViolationErrors.forEach(error => {
      if (error.propertyPath === 'sepa.businessEmail') {
        setError('businessEmail' as any, {
          type: 'violation',
          message: error.message || '',
        });
      } else {
        setError(error.propertyPath as any, {
          type: 'violation',
          message: error.message || '',
        });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [violationErrors]);

  const onSubmit = (data: any) => {
    data = prepareSaveData(data, 'customFields', company);
    data = prepareSaveData(data, 'globalCustomFields', company);
    dispatch(
      actions.fillUserInfo({
        ...data,
        dateOfBirth: format(new Date(data.dateOfBirth as Date), 'yyyy-MM-dd'),
        startDate: format(new Date(data.startDate as Date), 'yyyy-MM-dd'),
        // phoneNumber:
        //   data.phoneNumber.trim().length > 0 ? `${data.phoneNumberPrefix}${data.phoneNumber}` : '',
      }),
    );
    dispatch(actions.resetViolationErrors(null));
    if (hasStep3) updateStep(Number(stepNumber) + 1);
    else updateStep('summary');
  };

  return (
    <Box className='stepWrapper'>
      <Stepper />
      <Typography className='subtext' variant='h5'>
        {' '}
        {intl.formatMessage({
          id: 'step1.description.upper.profit',
          defaultMessage: `Hinweistext: Bitte ergänze Deine persönlichen Daten/einige Daten
          werden von proFit übernommen (tbd).`,
        })}
      </Typography>
      <Spacer height={12} />
      <RequiredInfo />
      <Spacer height={48} />
      {existingUserError && (
        <ModalError
          errorMessage={intl.formatMessage({
            id: 'schema.existing_user',
            defaultMessage:
              'A user with the same first name, last name and birthday already exists',
          })}
        />
      )}
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <PersonalData hasVoucher={true} />
          <Spacer height={48} />
          <ContactInfo hasVoucher={true} />
          <>
            <Spacer height={48} />
            <Typography variant='subtitle2'>
              {intl.formatMessage({
                id: 'step1.subsection.headline.start_time',
                defaultMessage: 'Start and duration',
              })}
            </Typography>
            <Spacer height={20} />
            <Box className='content'>
              <StartDatePicker
                control={control}
                disabled={isDisabledField('startDate', disabledFields, userInfo)}
                hidden={isHiddenField('startDate', hiddenFields, userInfo)}
                name='startDate'
                isMonthPicker={true}
                minimumDate={minimumDate}
                maximumDate={maximumDate}
                getValues={getValues}
                label={`${intl.formatMessage({
                  id: 'form.employee.field.service_date.label',
                  defaultMessage: 'Service start date',
                })}*`}
              />
              <CCPTextField
                control={control}
                disabled={isDisabledField('duration', disabledFields, userInfo)}
                hidden={isHiddenField('duration', hiddenFields, userInfo)}
                name='duration'
                label={`${intl.formatMessage({
                  id: 'form.employee.field.duration.label',
                  defaultMessage: 'Duration',
                })}*`}
              />
            </Box>
          </>
          <Spacer height={20} />
          {userInfo?.end_date_reached && (
            <Box className='hint'>
              <ErrorIcon fontSize='small' color='disabled' />
              <Typography variant='body1' fontSize={14} fontWeight='bold'>
                {intl.formatMessage(
                  {
                    id: 'form.employee.field.service_date.hint',
                    defaultMessage: 'Current license is still valid until {validUntil}',
                  },
                  { validUntil: format(new Date(userInfo?.end_date_reached), 'dd.MM.yyyy') },
                )}
              </Typography>
            </Box>
          )}

          <Box className='footer profit'>
            <Button onClick={() => updateStep(Number(stepNumber) - 1)} variant='outlined'>
              {intl.formatMessage({
                id: 'step2.back_button',
                defaultMessage: 'Back',
              })}
            </Button>
            <Button type='submit' variant='contained'>
              {hasStep3
                ? intl.formatMessage({
                    id: 'step2.next_button',
                    defaultMessage: 'Next: Payment method',
                  })
                : intl.formatMessage({
                    id: 'step2.continue',
                    defaultMessage: 'Continue: Summary page',
                  })}
            </Button>
          </Box>
        </form>
      </FormProvider>
    </Box>
  );
};

export default StepOneUTwo;
