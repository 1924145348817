import { styled, Box } from '@mui/material';

export const InfoWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '64px 0 56px',
  height: 'calc(100vh - 168px)',
  minHeight: 450,
  [theme.breakpoints.down('sm')]: {
    height: 'auto',
    padding: 0,
    minHeight: 'auto',
  },

  '.card': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 48,
    [theme.breakpoints.down('sm')]: {
      padding: 24,
      margin: 16,
    },
  },
  '.title': {
    marginBottom: 36,
  },
}));
