import { styled, Box } from '@mui/material';

const LandingWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.down('sm')]: {
    height: 'auto',
    padding: 0,
    minHeight: 'auto',
  },

  '.main-style': {
    '&.checkmark-background': {
      padding: '80px 0',
      backgroundSize: '40%',
      backgroundRepeat: 'no-repeat',
      '.headline': {
        color: theme.palette.primary.contrastText,
        textAlign: 'left',
        [theme.breakpoints.down('sm')]: {
          textAlign: 'center',
        },
      },
      '.limit-width': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        gap: '48px',
      },
      [theme.breakpoints.down('sm')]: {
        padding: '16px',
        backgroundImage: 'none',
        '.limit-width': {
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: 0,
        },
      },
      [theme.breakpoints.down('lg')]: {
        backgroundSize: '35%',
      },
      [theme.breakpoints.down('xl')]: {
        backgroundSize: '36%',
      },
    },
    '&.header-background': {
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    },
    padding: '16px',
    '.headline': {
      padding: '16px',
    },
    'h2,h3,h4,h5,h6': {
      marginTop: 0,
      marginBottom: 0,
    },
    '.landing-gallery-images': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '100%',
    },
    '.sign-up-box': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '8px',
      position: 'relative',
      width: 'min-content',
      '.badge': {
        position: 'absolute',
        left: '24px',
        top: 0,
        padding: '4px 8px',
        backgroundColor: theme.palette.primaryLight.main,
        color: theme.palette.primary.main,
        textTransform: 'uppercase',
        fontSize: '10px',
        fontWeight: 'bold',
        borderBottomLeftRadius: '4px',
        borderBottomRightRadius: '4px',
      },
      '.sign-up-main': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '24px 36px',
        gap: '24px',
        width: 'max-content',
        [theme.breakpoints.down('sm')]: {
          flexDirection: 'column',
        },
      },
      '.sign-up-info': {
        display: 'flex',
        gap: '8px',
        padding: '8px 36px 16px',
        backgroundColor: '#E2E8F0',
        borderBottomRightRadius: '8px',
        borderBottomLeftRadius: '8px',
        width: '100%',
        '&-icon': {
          color: theme.palette.primary.main,
          width: '20px',
          height: '20px',
        },
      },
    },
    '.image-banner-list': {
      padding: '0',
    },
    '.checkmark-item': {
      display: 'flex',
      alignItems: 'flex-start',
      gap: '8px',
      color: theme.palette.primary.contrastText,
      img: {
        marginTop: '5px',
      },
      p: { margin: '0' },
    },
  },
  '.limit-width': {
    maxWidth: '1024px',
    width: '100%',
    margin: 'auto',
    '.columns': {
      'h1, h2, h3, h4, h5': {
        lineHeight: '28px',
        margin: 0,
      },
      p: {
        [theme.breakpoints.down('sm')]: { textAlign: 'center' },
      },
    },
  },

  '.text-area a': {
    textDecoration: 'auto',
  },

  '.list-item p': {
    margin: 0,
  },

  '.mobile-paging': {
    padding: '0',
    width: '48px',
    height: '48px',
    minWidth: 'auto',
    borderRadius: '50%',
    borderColor: theme.palette.neutralDark.main,
    minHeight: 'auto',
    '&:hover': {
      borderColor: theme.palette.neutralDark.main,
    },
    '&:disabled': {
      opacity: '0.7',
    },
  },

  '.landing-empty-image': {
    color: theme.palette.primary.main,
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: '50%',
    display: 'flex',
    width: '96px',
    height: '96px',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 'auto',
  },

  '.landing-header': {
    backgroundColor: theme.palette.background.paper,
    padding: '60px 160px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: '48px 12px',
    },
  },
  '.landing-description': {
    padding: '60px 160px',
    [theme.breakpoints.down('sm')]: {
      padding: '48px 12px',
    },
    '.landing-description-image': {
      width: '100%',
      minHeight: ' 430px',
      background: theme.palette.primaryLight.main,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    '&-footer': {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row',
      gap: '12px',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
  },
  '.landing-footer': {
    background: theme.palette.background.paper,
    padding: '32px 16px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      padding: '36px 12px',
      flexDirection: 'column-reverse',
      gap: '60px',
    },
    '.landing-footer-left': {
      display: 'flex',
      flexDirection: 'row',
      gap: '100px',
      [theme.breakpoints.down('sm')]: {
        gap: '10px',
        flexDirection: 'column',
        alignItems: 'center',
      },
      '&-links': {
        [theme.breakpoints.down('sm')]: {
          width: '100%',
          justifyContent: 'space-between',
        },
      },
    },
    '.contacts-row': {
      display: 'flex',
      gap: '15px',
      [theme.breakpoints.down('sm')]: {
        gap: '12px',
        flexDirection: 'column',
        alignItems: 'center',
      },
    },
  },
  '.link': {
    fontFamily: 'Roboto-Medium',
    fontSize: '16px',
    color: theme.palette.primary.main,
    letterSpacing: '0.15px',
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    gap: '4px',

    '&:hover': {
      textDecoration: 'underline',
    },
  },
  '.footer-link': {
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
    lineHeight: 1.57,
    letterSpacing: 0.13,
    color: theme.palette.neutralDark.main,
    textDecoration: 'unset',

    '&:hover': {
      textDecoration: 'underline',
    },
  },

  button: {
    borderRadius: 18,
    fontFamily: 'Roboto-Medium',
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 1.38,
    padding: '7px 17px',
    textTransform: 'inherit',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxWidth: '100%',
    },
  },
}));

export default LandingWrapper;
