import { Typography, Card, Button } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Spacer from 'components/Spacer';
import { useIntl } from 'react-intl';
import { useAppSelector } from 'store';

import { FinishWrapper } from './Finish.styles';
import { CompanySelectors } from 'store/company/company.selectors';
import { IVoucher } from 'store/company/interfaces';
import { formatPrice } from 'utils/formatPrice';

const Finish: React.FC = () => {
  const intl = useIntl();

  const email = useAppSelector(state => state.company.successEmail);
  const duration = useAppSelector(state => state.company.successDuration);
  const userInfo = useAppSelector(CompanySelectors.getUserInfo);
  const data = useAppSelector(CompanySelectors.getCompanyData);
  const userVouchers = useAppSelector(CompanySelectors.getVouchers);

  const isVoucherError = false; // when BE is ready

  const hasVoucher = data?.proFit;

  return (
    <FinishWrapper>
      <Card sx={{ width: 620, minHeight: 320 }} className="card">
        {isVoucherError ? (
          <ErrorOutlineIcon color="error" fontSize="large" />
        ) : (
          <CheckCircleOutlineIcon color="success" fontSize="large" />
        )}
        <Spacer height={36} />
        <Typography variant="h3" className="title">
          {hasVoucher
            ? intl.formatMessage({
                id: 'finish.success.profit_title',
                defaultMessage: 'Registration was successful',
              })
            : intl.formatMessage({
                id: 'success.title',
                defaultMessage: 'Your request has been sent',
              })}
        </Typography>
        <Spacer height={24} />
        <Typography variant="body1" textAlign="center">
          {hasVoucher
            ? intl.formatMessage(
                {
                  id: 'finish.success.profit_description',
                  defaultMessage: 'A confirmation to {email_address} is on its way to you',
                },
                { email_address: email || '' },
              )
            : intl.formatMessage(
                {
                  id: 'success.description',
                  defaultMessage: `You will get notificaton on {email_address} once your request is approved.`,
                },
                { email_address: email || '' },
              )}
          {hasVoucher && (
            <>
              <Spacer height={24} />
              {intl.formatMessage({
                id: 'finish.success.profit_redeemed',
                defaultMessage: `Redeemed vouchers`,
              })}
              {userVouchers.data?.valid.map((item: IVoucher) => (
                <>
                  <br />
                  {`${item.voucher.code} (${formatPrice(item.voucher.initialValue)})`}
                </>
              ))}
              <Spacer height={12} />
              {intl.formatMessage(
                {
                  id: 'finish.success.profit_calculations',
                  defaultMessage: `With your proFit vouchers of {vouchers_sum}, you pay {payable_amount} for {period} Hansefit membership.`,
                },
                {
                  vouchers_sum: formatPrice(userVouchers.data?.totalVoucherValue.toString() || ''),
                  payable_amount: formatPrice(userVouchers.data?.payableAmount.toString() || ''),
                  period:
                    (duration && duration.length < 3
                      ? `${duration.slice(0, -1)} ${intl.formatMessage({
                          id: 'step1.form.duration.content',
                          defaultMessage: 'months',
                        })}`
                      : duration) || '',
                },
              )}
            </>
          )}
        </Typography>
        <Spacer height={24} />
        {isVoucherError && (
          <Button
            onClick={() =>
              window.open(
                intl.formatMessage({
                  id: 'error.cta.link_finish',
                  defaultMessage: 'https://hansefit.de/kontakt',
                }),
                '_blank',
              )
            }
            variant={'contained'}>
            {intl.formatMessage({
              id: 'error.cta',
              defaultMessage: 'Contact us',
            })}
          </Button>
        )}
      </Card>
    </FinishWrapper>
  );
};

export default Finish;
