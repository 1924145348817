import { Box, Button, Typography } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router';
import { IContactForm } from 'features/stepper/interfaces';
import personalInfoSchema from './contactSchema';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppDispatch, useAppSelector } from 'store';
import { actions } from 'store/company/company.slice';
import { format } from 'date-fns';
import { CompanySelectors } from 'store/company/company.selectors';
import { Stepper } from '..';
import { useViolationErrors } from 'utils';
import { useEffect, useMemo, useState } from 'react';
import ModalError from 'components/ModalError';
import PersonalData from '../FormParts/PersonalData';
import { getBirthDate, getMaxStartDate, getMinStartDate } from 'utils/prepareDates';
import Spacer from 'components/Spacer';
import StartDatePanel from '../FormParts/StartDatePanel';
import RequiredInfo from '../FormParts/RequiredInfo';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

interface StepOneProps {
  updateStep: (stepNumber: number) => void;
}

const StepOne: React.FC<StepOneProps> = ({ updateStep }) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { id, stepNumber } = useParams();
  const dispatch = useAppDispatch();
  const company = useAppSelector(CompanySelectors.getCompanyData);
  const userInfo = useAppSelector(CompanySelectors.getUserInfo);

  const [existingUserError, setExistingUserError] = useState<boolean>(false);
  const violationErrors = useAppSelector(CompanySelectors.getViolationErrors);

  useEffect(() => {
    if (company && !company.startDate) {
      navigate('/');
    }
  }, [company, navigate]);

  const minimumDate = getMinStartDate(company);
  const maximumDate = getMaxStartDate(company);

  const methods = useForm<IContactForm>({
    defaultValues: useMemo(
      () => ({
        title: userInfo?.title || '',
        firstName: userInfo?.firstName || '',
        lastName: userInfo?.lastName || '',
        gender: userInfo?.gender || '',
        dateOfBirth: userInfo?.dateOfBirth || null,
        startDate: format(
          new Date(userInfo?.startDate ? userInfo.startDate : minimumDate),
          'yyyy-MM-dd',
        ),
      }),
      [userInfo, company],
    ),
    resolver: yupResolver(
      personalInfoSchema(
        { min: getBirthDate('min'), max: getBirthDate('max') },
        { min: minimumDate, max: maximumDate },
      ),
    ),
  });

  const { handleSubmit, watch, clearErrors, setError, reset } = methods;

  useEffect(() => {
    reset({
      startDate: format(
        new Date(userInfo?.startDate ? userInfo.startDate : minimumDate),
        'yyyy-MM-dd',
      ),
    });
  }, [company?.startDate, reset]);

  const addViolationErrors = useViolationErrors({
    errors: violationErrors || null,
    scope: 'employee_management',
    watch,
    clearErrors,
  });

  useEffect(() => {
    addViolationErrors.forEach(error => {
      if (error.code.toUpperCase() === 'NOT_UNIQUE_NAME_AND_BIRTHDATE_IN_COMPANY') {
        setExistingUserError(true);

        if (error.propertyPath === 'firstName, lastName, dateOfBirth') {
          setError('firstName', {
            type: 'violation',
            message: error.message || '',
          });
          setError('lastName', {
            type: 'violation',
            message: error.message || '',
          });
          setError('dateOfBirth', {
            type: 'violation',
            message: error.message || '',
          });
        }
      } else {
        //we need to add logic here when it will needs
        console.log('first step violation error');
      }
    });
  }, [setError, violationErrors]);

  const onSubmit = (data: any) => {
    dispatch(
      actions.fillUserInfo({
        ...data,
        dateOfBirth: format(new Date(data.dateOfBirth as Date), 'yyyy-MM-dd'),
        startDate: format(new Date(data.startDate as Date), 'yyyy-MM-dd'),
      }),
    );
    updateStep(Number(stepNumber) + 1);
  };

  return (
    <Box className='stepWrapper'>
      <Stepper />
      <Box className='headline'>
        {existingUserError && (
          <ModalError
            isWarningMessage
            errorMessage={intl.formatMessage({
              id: 'not_unique_user_data_error_title',
              defaultMessage: 'Is the information correct? ',
            })}
            descriptionErrorMessage={intl.formatMessage({
              id: 'not_unique_user_data_error_description',
              defaultMessage:
                'If so, please contact your Hansefit representative to have a user created for you.',
            })}
          />
        )}
        <Typography variant='h5'>
          {intl.formatMessage({
            id: 'step1.description',
            defaultMessage: 'Please, provide your personal data to set up your account',
          })}
        </Typography>
        <Spacer height={12} />
        <RequiredInfo />
      </Box>
      <Spacer height={24} />
      <Box className='section'>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <PersonalData hasVoucher={false} />
            <StartDatePanel
              isFlexibleContract={company?.regularStartDate === 'flexible'}
              maximumDate={maximumDate}
              minimumDate={minimumDate}
            />
            {company?.autoConfirmSignup === 'no' && (
              <>
                <Spacer height={12} />
                <Box className='required-wrapper'>
                  <ErrorOutlineIcon />
                  <Typography variant='h5' fontSize={14}>
                    {company?.regularStartDate === 'flexible'
                      ? intl.formatMessage({
                          id: 'stepper.manual_approval.notice.flexible',
                          defaultMessage:
                            'Please note that your account must be activated by your employer before you can start.',
                        })
                      : intl.formatMessage({
                          id: 'stepper.manual_approval.notice.start_of_month',
                          defaultMessage:
                            'Please note that your account must be activated by your employer by the end of the month so that you can start in the following month.',
                        })}
                  </Typography>
                </Box>
              </>
            )}
            <Box
              className={`footer ${
                company?.landingPage && company?.landingPage.length !== 0 ? '' : 'first'
              }`}>
              {company?.landingPage && company?.landingPage.length !== 0 && (
                <Button onClick={() => navigate(`/${id}/landing`)} variant='outlined'>
                  {intl.formatMessage({
                    id: 'step2.back_button',
                    defaultMessage: 'Back',
                  })}
                </Button>
              )}
              <Button type='submit' variant='contained'>
                {intl.formatMessage({
                  id: 'step1.next_button',
                  defaultMessage: 'Next: Contact info',
                })}
              </Button>
            </Box>
          </form>
        </FormProvider>
      </Box>
    </Box>
  );
};

export default StepOne;
