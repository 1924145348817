import { styled, Box } from '@mui/material';

export const FinishWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '64px 0 56px',
  height: 'calc(100vh - 168px)',
  minHeight: 450,
  [theme.breakpoints.down('sm')]: {
    height: 'auto',
    padding: 0,
    minHeight: 'auto',
  },

  '.card': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 48,
    [theme.breakpoints.down('sm')]: {
      padding: 24,
      margin: 16,
      width: '100%',
      minHeight: 'auto',
    },
  },
  '.ctaContainer': {
    display: 'flex',
    justifyContent: 'flex-end',
    button: {
      borderRadius: 18.5,
    },
  },

  button: {
    borderRadius: 18,
    fontFamily: 'Roboto-Medium',
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 1.38,
    padding: '7px 17px',
    textTransform: 'inherit',
    minWidth: 'fit-content',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxWidth: '100%',
    },
  },
}));
