import { Typography, Card, Button, Box } from '@mui/material';
import Spacer from 'components/Spacer';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useIntl } from 'react-intl';
import CCPPasswordTextField from 'components/FormComponents/PasswordTextField';
import { useAppDispatch, useAppSelector } from 'store';

import { PasswordWrapper } from './Password.styles';
import DefaultPasswordSchema from './passwordSchema';
import React, { useEffect } from 'react';
import { CompanyActions } from 'store/company';
import { useNavigate, useParams } from 'react-router';
import { CompanySelectors } from 'store/company/company.selectors';
import ModalError from 'components/ModalError';

const Password: React.FC = () => {
  const intl = useIntl();
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const error = useAppSelector(CompanySelectors.getError);
  const password = useAppSelector(CompanySelectors.getPassword);
  const prefilledPassword = useAppSelector(state => state.company.prefilled.password);
  const userInfo = useAppSelector(CompanySelectors.getUserInfo);
  const data = useAppSelector(CompanySelectors.getCompanyData);
  const hasVoucher = data?.proFit;
  const path = hasVoucher ? `/${id}/step/0` : `/${id}/step/1`;
  // const api = useAppSelector(InitialPasswordSelectors.api);

  const methods = useForm<{ password: string }>({
    defaultValues: {
      password: '',
    },
    resolver: yupResolver(DefaultPasswordSchema),
  });

  const { control, handleSubmit } = methods;

  const onSubmit = (data: { password: string }) => {
    dispatch(CompanyActions.checkPassword({ id, password: data.password }));
  };

  useEffect(() => {
    if (prefilledPassword) {
      onSubmit({ password: prefilledPassword as string });
    }
  }, [prefilledPassword]);

  useEffect(() => {
    if (password) {
      if (data?.landingPage && !userInfo) navigate(`/${id}/landing`);
      else navigate(path);
    }
  }, [password]);

  return (
    <PasswordWrapper>
      <Card sx={{ width: 620, minHeight: 370 }} className='card' data-testid='password-card'>
        <Typography variant='h3' className='title'>
          {intl.formatMessage({
            id: 'password.title',
            defaultMessage: 'Password protection',
          })}
        </Typography>
        <Spacer height={36} />
        {error && (
          <ModalError
            errorMessage={intl.formatMessage({
              id: 'schema.enter_password.invalid',
              defaultMessage: 'Password is invalid',
            })}
          />
        )}
        <Typography variant='body1' sx={{ textAlign: ['center', 'left'] }}>
          {intl.formatMessage({
            id: 'password.description',
            defaultMessage: `Please, enter the password to start registration.`,
          })}
        </Typography>
        <Spacer height={40} />
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
            <CCPPasswordTextField
              label={intl.formatMessage({
                id: 'form.field.password',
                defaultMessage: 'Password',
              })}
              name={'password'}
              control={control}
              isRepeat={false}
            />
            {/* {api.changeTemporaryPassword.error && (
              <>
                <Spacer height={32} />
                <AsyncError variant={'body1'}>
                  {intl.formatMessage({
                    id: api.changeTemporaryPassword.error,
                  })}
                </AsyncError>
              </>
            )} */}
            <Spacer height={50} />
            <Box className={'ctaContainer'}>
              <Button type='submit' variant={'contained'} color={'primary'}>
                {intl.formatMessage({ id: 'password.confirm', defaultMessage: 'Confirm' })}
              </Button>
            </Box>
          </form>
        </FormProvider>
      </Card>
    </PasswordWrapper>
  );
};

export default Password;
