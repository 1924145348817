import { Box, Button, Typography } from '@mui/material';
import { BaseSetting } from 'features/landing/interfaces';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ErrorSvg from '@mui/icons-material/Error';
import Spacer from 'components/Spacer';

const SignUpBox: React.FC<
  BaseSetting & {
    internal?: boolean;
    enableInfoBox: boolean;
    info?: string;
    note: string;
    text: string;
    badge: string;
    button: {
      text: string;
      style: 'primary' | 'secondary' | 'critical';
      link: {
        path: string;
        target?: '_blank' | '_self' | '_top' | '_parent' | 'lightbox';
      };
    };
  }
> = ({
  internal,
  badge,
  text,
  info = '',
  enableInfoBox,
  note,
  backgroundColor = '#dedede',
  button: {
    text: buttonText,
    style,
    link: { path: link, target },
  },
}) => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  const removeFirstSlash = (url: string) => (url.startsWith('/') ? url.substr(1) : url);
  const goToPage = () => {
    if (link.includes('://')) {
      window.open(link, target || '_blank');
    } else {
      if (!target || target !== '_blank') {
        navigate(
          id && link.includes(id)
            ? `/${removeFirstSlash(link)}`
            : `/${id}/${removeFirstSlash(link)}`,
        );
      } else {
        window.open(`${window.location.origin}/${removeFirstSlash(link)}`, target || '_blank');
      }
    }
  };
  return (
    <Box
      className={internal ? '' : 'main-style'}
      display='flex'
      flexDirection='column'
      alignItems='center'
      data-testid='signup-box-item'>
      <Box className='sign-up-box' sx={{ backgroundColor }}>
        <span className='badge'>{badge}</span>
        <Box className='sign-up-main'>
          <Typography
            marginTop='1px'
            variant='h2'
            dangerouslySetInnerHTML={{
              __html: text,
            }}
          />
          <Button
            variant='contained'
            onClick={goToPage}
            sx={{ padding: '12px 18px' }}
            color={style === 'critical' ? 'error' : style}>
            {buttonText}
          </Button>
        </Box>
        {enableInfoBox && (
          <Box className='sign-up-info' sx={{ backgroundColor }}>
            <ErrorSvg className='sign-up-info-icon' />
            <Typography variant='subtitle1' fontSize='14px'>
              {info}
            </Typography>
          </Box>
        )}
      </Box>
      <Spacer height='60px' />
      <Typography variant='subtitle1' fontSize='12px' color='#CBD5E1'>
        {note}
      </Typography>
    </Box>
  );
};

export default SignUpBox;
